import React, {useState} from 'react';

import { Button, CircularProgress, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useMutation } from "@apollo/client";
import { SEND_EMAIL_TO_SUPPORT, UPDATE_CATERE } from "../../Graphql/mutations";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {toggleSnackbar} from "../../ReactiveVariables/index";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useNavigate} from "react-router-dom";
import {surveyYupSchema} from "./configurations";

export default function Survey(props) {
    const navigate = useNavigate();
    const currentData = props?.currentDetails?.getOneCaterer || props?.userValues?.updateCaterer;
    const userType = props?.userType?.user;
    const userData = props?.user?.data?.createUserIdentity || props?.userValues?.updateCaterer;

    const searchParams = new URLSearchParams(location.search);
    const redirectPath = searchParams.get("redirect") || location.state?.redirect || "/";

    const [checkedValues, setCheckedValues] = useState({
        referral: false,
        wordOfMouth: false,
        linkedIn: false,
        ads: false,
        socialMedia: false,
        other: false,
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(surveyYupSchema), mode: 'onChange' });

    const [sendEmailToSupport, {loading: sendEmailLoading}] = useMutation(SEND_EMAIL_TO_SUPPORT);

    const [updateCaterer, {loading: updateCatererLoading}] = useMutation(UPDATE_CATERE, {
        onCompleted: (res) => {
            props.setUserValues(res);
            if (userType === 'Customer') {
                props.setActiveStep(1)
            }
            props.setActiveStep(6);
        },
    });

    const handleCheckboxChange = (name) => (event) => {
        setCheckedValues({ ...checkedValues, [name]: event.target.checked });
    };

    function onSubmit(value) {
        try {
            if (!checkedValues.other) {
                value.answer = '';
            }
            let finalValue = {...value, answerOptions: {...checkedValues}};
            if (props?.userType?.user === "Caterer") {
                const surveyData = {
                    tabValue: "5",
                }
                Promise.all([
                    updateCaterer({ variables: {
                            id: props?.id,
                            catererId: currentData?.catererUsers[0]?.catererId || props.catererId,
                            data: surveyData
                        }
                    }),
                    sendEmailToSupport({variables: {data: {...finalValue}}})
                ]).then((res) => {
                    if (!sendEmailLoading) {
                        toggleSnackbar({
                            status: true,
                            message: "The survey has been successfully sent",
                            variant: 'success'
                        });
                        navigate(redirectPath);
                    }
                }).catch((err) => {
                    const message = err && err.message;
                    toggleSnackbar({
                        status: true,
                        message: message,
                        variant: "error",
                    });
                });
            } else {
                sendEmailToSupport({variables: {data: {...finalValue}}})
                    .then((res) => {
                        if (!sendEmailLoading) {
                            toggleSnackbar({
                                status: true,
                                message: "The survey has been successfully sent",
                                variant: 'success'
                            });
                            navigate("/");
                        }
                    }).catch((err) => {
                    const message = err && err.message;
                    toggleSnackbar({
                        status: true,
                        message: message,
                        variant: "error",
                    });
                });
            }

        } catch (e) {
            const message = e?.message || 'An error occurred';
            toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
            });
        }
    }

    function skip() {
        toggleSnackbar({
            status: true,
            message: "Survey Info skipped successfully",
            variant: "success",
        });
        navigate(redirectPath);
    }

    const atLeastOneCheckboxSelected = Object.values(checkedValues).some(value => value);

    return (
        <div className="create-step-form">
            <h5 className="h5">How did you hear about us?</h5>
            <h6 className="subhead">You can select multiple options as offered.</h6>

            <div className="row addBusinessSet">
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='create-step-form'>
                            <div className='row'>
                                <div className='col-md-6 textBox d-none'>
                                    <TextField
                                        label="FIRST NAME*"
                                        autoFocus
                                        variant="outlined"
                                        className="textField allFeild"
                                        defaultValue={userData?.firstName || ''}
                                        {...register('firstname')}
                                    />
                                    {errors.firstname && (
                                        <span className="error-msg">{errors.firstname.message}</span>
                                    )}
                                </div>
                                <div className='col-md-6 textBox d-none'>
                                    <TextField
                                        label="LAST NAME*"
                                        autoFocus
                                        variant="outlined"
                                        className="textField allFeild"
                                        defaultValue={userData?.lastName || ''}
                                        {...register('lastname')}
                                    />
                                    {errors.lastname && (
                                        <span className="error-msg">{errors.lastname.message}</span>
                                    )}
                                </div>
                                <div className='textBox d-none'>
                                    <TextField
                                        label="EMAIL*"
                                        autoFocus
                                        variant="outlined"
                                        className="textField allFeild"
                                        defaultValue={userData?.email || ''}
                                        fullWidth
                                        {...register('email')}
                                    />
                                    {errors.email && (
                                        <span className="error-msg">{errors.email.message}</span>
                                    )}
                                </div>
                                <div>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox
                                            checked={checkedValues.referral}
                                            onChange={handleCheckboxChange('referral')}
                                        />} label="Referral"/>
                                        <FormControlLabel control={<Checkbox
                                            checked={checkedValues.wordOfMouth}
                                            onChange={handleCheckboxChange('wordOfMouth')}
                                        />} label="Word of Mouth"/>
                                        <FormControlLabel control={<Checkbox
                                            checked={checkedValues.linkedIn}
                                            onChange={handleCheckboxChange('linkedIn')}
                                        />} label="LinkedIn"/>
                                        <FormControlLabel control={<Checkbox
                                            checked={checkedValues.ads}
                                            onChange={handleCheckboxChange('ads')}
                                        />} label="Email Search Engine / Ads"/>
                                        <FormControlLabel control={<Checkbox
                                            checked={checkedValues.socialMedia}
                                            onChange={handleCheckboxChange('socialMedia')}
                                        />} label="Social Media"/>
                                        <FormControlLabel control={<Checkbox
                                            checked={checkedValues.other}
                                            onChange={handleCheckboxChange('other')}
                                        />} label="Other"/>
                                    </FormGroup>
                                    {!atLeastOneCheckboxSelected && (
                                        <span
                                            className="error-msg options-msg">You need to choose one of the options
                                        </span>
                                    )}
                                </div>
                                <div className='textBox'>
                                    <TextField
                                        disabled={!checkedValues.other}
                                        multiline
                                        rows={4}
                                        fullWidth
                                        label="TELL US HOW YOU HEARD ABOUT US*"
                                        {...register('answer')}
                                    />
                                </div>
                                {errors.answer && (
                                    <span className="error-msg">{errors.answer.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="createStepBtn">
                                <Button
                                    className="cancelBtn"
                                    variant="contained"
                                    color="secondary"
                                    disabled={props?.userType?.user === "Customer" +
                                        ""}
                                    onClick={() => props?.setActiveStep(4)}
                                >
                                    Previous
                                </Button>
                                <Button
                                    className="saveBtn"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={!atLeastOneCheckboxSelected || !!Object.keys(errors).length}
                                >
                                    {sendEmailLoading || updateCatererLoading ?
                                        <CircularProgress color="inherit" size={20}/> :
                                        "Send and Proceed "
                                    }<ArrowForwardIcon className="btnArrow"/>
                                </Button>
                            </div>
                            <div className="text-end mt-3">
                                <Button className="mt-2" style={{color: "#646777", fontWeight: '600'}}
                                        onClick={() => skip()}>Skip for now <ArrowForwardIcon
                                    className="ms-2"/></Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
