//This is for the login page
import React from 'react';

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client/react";
import {
  Button,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import LoginHeader from "./LoginHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from '@mui/material';
import * as yup from "yup";
import {
  authVar,
  userDeatils,
  toggleSnackbar,
} from "../../ReactiveVariables/index";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LOGIN_ADMIN, FORGET_PASS } from "../../Graphql/mutations"
import { useCookies } from 'react-cookie';
import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import { useAppSelector } from '@services/redux';

export default function Login() {
  const location = useLocation();
  const userType = location.state;
  const [showPassword, setShowPassword] = useState(false);
  const [forgetPass, setForgetPass] = useState(userType==="tokenExpired"?true:false);
  const [rememberMe, setRememberMe] = useState(true)
  const [cookies, setCookie,removeCookie] = useCookies(['user','image','userType','@authToken']);
  const order = useAppSelector(state => state.order.draft)
  // yup for form validation
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email address")
      .transform((value) => (value ? value.trim() : '')) ,
    password: forgetPass ? null : yup.string().required("Password is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  //for toggling text/password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate()
  const [Login, { loading }] = useMutation(LOGIN_ADMIN, {
    onCompleted: (res) => {
      const userDetails = {
        data:res
      };
      setCookie('user', res?.login?.fullName, {
        path: '/',
        domain: '.caterplace.com',

      });
      setCookie('image', res?.login?.imageUrl, {
        path: '/',
        domain: '.caterplace.com',
      });
      setCookie('userType', res?.login?.userType, {
        path: '/',
        domain: '.caterplace.com',
      });
      // if (rememberMe === true) {
      //   setCookie("@authToken", res.login.token, { maxAge: 2592000 })
      // } else {
      //   setCookie("@authToken", res.login.token)
      // }
    }
  })
  const [forgetPassword] = useMutation(FORGET_PASS);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const refreshPage = () => {
    window.location.reload();
  }

  useEffect(()=>{
    if(userType==="tokenExpired"){
    toggleSnackbar({
      status: true,
      message: "Sorry, your password reset link has expired. Please click 'Request' to request a new password reset link. Thank you.",
      variant: "error",
    });
  }
  },[userType==="tokenExpired"])
  
  useEffect(()=>{
    if(userType==="noAuth"){
    toggleSnackbar({
      status: true,
      message: "Your session has expired. Please log back in to continue.",
      variant: "error",
    });
  }
  },[userType==="noAuth"])
  // function getUuid() {
  //   return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
  //     (
  //       c ^
  //       (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
  //     ).toString(16)
  //   );
  // }
  // const [uuId, setUuid] = useState(getUuid());
  function onSubmit(params) {
    // switching between login / forget password mutation
    if (!forgetPass) {
      // login
      params["rememberMe"] = rememberMe
      // if (userType?.user) {
        params["userType"] = "User"
        params['uuid'] = order.uuid
      // }
      try {
        Login({ variables: params })
          .then((res) => {
            const responseData = res.data && res.data.login;
            const token = responseData.token;
            if(rememberMe){
              localStorage.setItem("@authToken", token);
            }
            else{
              setCookie("@authToken", token)
            }
            // setting the the response to reactive variables.
            userDeatils({ data: res?.data });
            authVar({
              loaded: true,
              auth: true,
            });
            toggleSnackbar({
              status: true,
              message: "Login successful",
              variant: "success",
            });

            //check customer or caterer login
            if (responseData?.userType === "Customer") {
              navigate("/")
            }
            else {
              // check the reg.falg for navigating to location/register screen
              if (responseData.regFlag === "Completed" || responseData?.userSrc === "AddBusiness") {
                navigate("/summary")
              } else {
                navigate("/register");
              }
            }
            if(userType?.faqReferFriend==="faqReferFriend"&&responseData?.userType === "Customer"){
              navigate("/referFriend")
            }
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      // forget password
      try {
        forgetPassword({ variables: { email: params.email } })
          .then((res) => {
            const message = res?.data?.forgotPassword?.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "success",
            });
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }


  }

  return (
    <div className="wrap wrap-height">
      {/* login header */}
      <LoginHeader setForgetPass={setForgetPass} userType={userType?.user} />
      <div className="container-fluid p-0 wrap-height">
        <div className="row m-0 wrap-height">
          <div className="col-xl-5 p-0 wrap-height create-form-left">
            <div className="login-img">
              <img
                src="../assets/images/login-new.jpg"
                className="img-fluid"
                alt="Login"
              />
              <h3 className="h3">
                You are only a few more clicks away from earning CaterCash
              </h3>
            </div>
          </div>
          <div className="col-xl-7 p-0 wrap-height login-left create-form-right">

            <div className="login-box-frontend">
              {userType?.user !== "Customer" ?
                !forgetPass ? <h4 className="h4">Login to CaterPlace</h4> : <h4 className="h4">Forgot Password</h4> : null}
              <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                className="login-box create-step-form"
              >
                {/* swithching between login form and forgot password based on the forgetpass value*/}

                <div className="textBox">
                  <TextField
                    id="loginEmail"
                    label="Email"
                    autoFocus
                    variant="outlined"
                    className="textField allFeild"
                    {...register("email")}
                  />
                  {errors.email && (
                    <span className="error-msg">{errors.email.message}</span>
                  )}
                </div>
                {!forgetPass && <div className="textBox">
                  <TextField
                    id="loginPassword"
                    label="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    className="textField allFeild"
                    {...register("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.password && (
                    <span className="error-msg">Password is required</span>
                  )}
                </div>}

                {!forgetPass && <div className="login-check d-flex align-items-center justify-content-between">
                  <FormGroup>
                    <FormControlLabel
                      checked={rememberMe}
                      control={<Checkbox size="small" />}
                      label="Remember me"
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                  </FormGroup>
                  <Link to="#" onClick={() => setForgetPass(true)}>Forgot password ?</Link>
                </div>}

                <div className="createOneLink">
                  {/* !forgetPass ? <p>
                    Don’t Have an Account? <Link to="/register" state={userType}>Create One</Link>
                  </p> :  */}
                  
                  {forgetPass && <p onClick={() => setForgetPass(false)} style={{ cursor: 'pointer', textAlign: 'right', textDecoration: 'underline' }}>Back to Login</p>}
                </div>

                <Button type="submit" variant="contained" className="login-btn" disabled={loading}>
                  {loading ? <CircularProgress color="inherit" size={20} /> : forgetPass ? "Request" : "Log In"}
                </Button>

                <div className="login-separator">
                  <label>or</label>
                </div>
                <div className="login-create">
                  <h4>Create Account</h4>
                 <div className="new-account-btn">
                 <Link state={{user:'Customer',userType}} to="/register"><Button variant="contained">New Customer</Button></Link>
                  
                  <Link state={{user:'Caterer'}} to="/register"><Button variant="contained">New Caterer</Button></Link>
                 </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
