import React, { useState, } from "react";
import { formatter } from "../../../Modules/Common/commonUtils";
import { Skeleton } from "@mui/material";
import { ca } from "date-fns/locale";

interface ICatererMainInfoProps {
  name: string;
  cash: number;
  reviewRate: number;
  reviewCount: number;
  address: string;
  zip: string;
  phone: string;
  minOrder: number;
  deliveryFee: string;
  image: string;
  reviewsData: [any];
}

export function CatererMainInfo({
  name,
  cash,
  reviewRate,
  reviewCount,
  address,
  zip,
  phone,
  minOrder,
  deliveryFee,
  image,
  reviewsData
}: ICatererMainInfoProps) {
  const caterCash = cash ? cash : 5;
  const [isLoaded, setIsLoaded] = useState(false);

  function getTopTwoReviews(reviews) {
    if (!reviews || reviews.length === 0) {
      return [];
    }

    const reviewsCopy = [...reviews];

    const sortedReviews = reviewsCopy.sort((a, b) => b.rating - a.rating);

    return sortedReviews.slice(0, 2);
  }

  const topReviews = getTopTwoReviews(reviewsData);

  const onImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className="container">
      <h2 className="h2">{name}</h2>
      <div className="row">
        <div className={topReviews.length > 0 ? 'col-md-8' : 'col-md'}>
          <ul className="d-flex detail-main-points">
            <li className="dmp-bg">
              <img src="../assets/images/cashflow.svg" className="img-fluid" alt="Icon" />
              <b>{cash || 5}%</b>
              CaterCash
            </li>
            {reviewRate ? (
              <li className="dmp-bg">
                <img src="../assets/images/Star1.svg" className="img-fluid" alt="Icon" />
                <b>{reviewRate.toFixed(1)}</b>({reviewCount}Review{reviewCount === 1 ? "" : "s"})
              </li>
            ) : null}
            <li className="dmp-locations">
              <span className="dmp-bg">
                <img src="../assets/images/Icon-Location1.svg" className="img-fluid" alt="Icon" />
              </span>
              {address},{zip}
            </li>
            <li>
              <span className="dmp-bg">
                <img src="../assets/images/call.svg" className="img-fluid" alt="Icon" />
              </span>
              {phone}
            </li>
            <li>
              <span className="dmp-bg">
                <img src="../assets/images/dollar-sign.svg" className="img-fluid" alt="Icon" />
              </span>
              Min Order: {formatter.format(minOrder)}
            </li>
            <li>
              <span className="dmp-bg">
                <img src="../assets/images/truck.svg" className="img-fluid" alt="Icon" />
              </span>
              Delivery Fee: {deliveryFee}
            </li>
          </ul>

          <p className="lead py-3" style={{ fontWeight: 'bold' }}>See What Customers Are Saying</p>
          <div className="row">
            {topReviews.length > 0 && (topReviews.map((review, index) => (
              <div key={index} className="col-md-6 review-item">
                <div className="review-item-header">
                  <h6>{review?.userName || "Anonymous"}</h6>
                  <div className="review-item-rating">
                    {[...Array(review?.rating)].map((_, i) => (
                      <img key={i} src="../assets/images/Star1.svg" className="img-fluid" alt="Star" />
                    ))}
                  </div>
                </div>
                <p>{review?.text.length > 250 ? `${review.text.substring(0, 250)}...` : review.text}</p>
              </div>
            )))}
          </div>
        </div>
        {topReviews.length > 0 && (
          <div className="col-md">
            <div className="cater-list-item-img d-flex justify-content-end" style={{ position: 'relative' }}>
              {!isLoaded && (
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: "60%" }} />
                </Skeleton>
              )}
              <img
                onLoad={onImageLoad}
                style={{ height: !isLoaded && "0", borderRadius: '10px' }}
                src={
                  image === "#"
                    ? "../assets/images/home.jpg"
                    : image
                      ? image
                      : "../assets/images/home.jpg"
                }
                className="img-fluid"
                alt="Cater"
              />
              <div className="cater-cash-tag">
                <b>{`${caterCash}%`}</b>
                <label>CaterCash</label>
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
}
