import React from 'react'

import LoginHeader from "../../Login/LoginHeader";
import Footer from "../../Common/footer";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {useNavigate } from "react-router-dom";
import {  userDeatils,authVar } from "../../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import {Helmet} from "react-helmet";
import { useEffect } from "react";
export default function Caterers() {
  const navigate = useNavigate()
  const auth = useReactiveVar(authVar);
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  })
  var trusted = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 5000,
    pauseOnHover: true,
    cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
         
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  // var trusted2 = {
  //   dots: false,
  //   infinite: true,
  //   arrows: false,
  //   slidesToShow: 6,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 0,
  //   speed: 5000,
  //   pauseOnHover: true,
  //   cssEase: 'linear',
  //   rtl: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
         
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };



  return (
    <div>
      <Helmet>
        <title>CaterPlace - Corporate Catering</title>
        <meta name="Keywords" />
        <meta name="description" content="Looking to expand your catering footprint in today's competitive market?" />
      </Helmet>
      <LoginHeader />
      <section className="about-banner caterer-banner overlay">
        <div className="container">
          <div className="about-banner-cnt">
            <h2 className="h2">CaterPlace: The Corporate Catering Platform</h2>
            <p>CaterPlace is loyalty based rewards platform that help restaurants increase their footprint, while attracting their repeat business from new customers</p>
           {!auth?.auth&&<Button variant="contained" color="primary" onClick={()=>navigate("/login")}>Sign Up</Button>}
          </div>
        </div>
      </section>
      <section className="why-cp">
        <div className="container">
          <h3 className="h3">Office Catering Solutions For Businesses</h3>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="why-cp-img">
                <img src="../assets/images/order-list.png" className="img-fluid w-100" alt="Caterplace" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="why-cp-cnt">
               <ul>
                  <li>Receive catering orders from local corporate catering customers.</li>
                  <li>Larger catering orders (Our average order is $400+).</li>
                  <li>Reduced commission rates on catering orders.</li>
                  <li>No commission taken from delivery fee and tips.</li>
                  <li>Reward customers through a brand loyalty platform.</li>
                  <li>Full customer and order management support from our catering concierge team.</li>
                  <li>Make your own menu updates or have us update them for you (3-5 business days).</li>
                  <li>Free branded website, upgrade, and catering links available.</li>
                </ul>
              </div>
            </div>
          </div>
          
        </div>
      </section>
      <section className="how-it-work">
        <div className="container">
        <h3 className="h3">How It Works</h3>
        <div className="row">
          <div className="col-md-4">
            <div className="hw-item">
              <img src="../assets/images/hw-1.svg" className="img-fluid" alt="How it work" />
              <label className="count">1</label>
              <p>Register your catering restaurant with CaterPlace</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="hw-item">
              <img src="../assets/images/hw-2.svg" className="img-fluid" alt="How it work" />
              <label className="count">2</label>
              <p>Our menu team will have your menu ready within 5-7 business days</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="hw-item">
              <img src="../assets/images/hw-3.svg" className="img-fluid" alt="How it work" />
              <label className="count">3</label>
              <p>Caterer reviews menu, and if everything looks good we go live!</p>
            </div>
          </div>
        </div>
        </div>
      </section>
      <section className="trusted-restaurants">
        <div className="container-fluid">
          <h4 className="h4 text-center">Trusted and Used By The Best Restaurants</h4>
          <Slider  {...trusted} className="trustedSlider">
            <div>
              <img src="../assets/images/wood-and-fire-2.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/alhorno.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/springbone.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/applespice.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/bareburger.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/havana-central.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/boneybrains.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/locanut.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/lazzaras-pizza.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/pick-a-bangle.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/shawarmania.svg" className="img-fluid " alt="Caterplace" />
            </div>
            
           
            
          </Slider>
          {/* <Slider  {...trusted2} className="trustedSlider2">
            <div>
              <img src="../assets/images/tr-pg.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-wood.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-hummus.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-gg.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-oxido.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-as.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-pg.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-wood.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-hummus.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-gg.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-oxido.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/tr-as.svg" className="img-fluid " alt="Caterplace" />
            </div>
          </Slider> */}
        </div>
      </section>

      <section className="why-cp convinced">
        <div className="container">  
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="why-cp-cnt">
                <h1 className="h1">Not Convinced?</h1>
                <h4 className="h4">Here's a few more reasons to sign up</h4>
                <ul>
                  <li>No sign up fee or contract required to receive orders from CaterPlace.</li>
                  <li>Gain exposure to our large network of corporate catering clients.</li>
                  <li>Less competition for orders, we only work with the best caterers.</li>
                  <li>Free marketing and promotional material available for partner restaurants.</li>
                  <li>U.S based concierge team and order management team.</li>
                  {/* <li>Our average user earns over <font>$3,000 a year to spend at Amazon</font> through CaterCash rewards!</li>
                  <li>On the road and need help entering an order? No problem, our concierge team can submit the order for you!</li>
                  <li>Don't see your caterer on our platform? Just let us know which caterer you'd like us to add, and we'll give you a <font>$25 CaterCash reward</font> once we add them!</li>
                  <li>Know any friends that also order catering? <font>Earn a $100</font> on each friend you refer to CaterPlace that submits a qualifying order of $150 or more!</li>
                  <li>Need a custom solution? No problem, just ask!</li> */}
                </ul>

                <div className="action-btn">
                {!auth?.auth&&<Button variant="contained" color="primary"  onClick={()=>navigate("/login")}>Sign Up</Button>}
                  <Button className="call-btn" variant="contained" color="secondary" onClick={()=>navigate("/contact")}>Schedule a Call</Button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="why-cp-img">
                <img src="../assets/images/convinced-drawing.svg" className="img-fluid w-100" alt="Caterplace" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="trusted-restaurants">
        <div className="container-fluid">
          <h4 className="h4 text-center">Trusted Catering Service For Corporations</h4>
          <Slider  {...trusted} className="trustedSlider">
            <div>
              <img src="../assets/images/client-bausch-health.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-hsbc.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-stripe.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-abbott.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-aramark.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-charter-communications.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-deloitte.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-fenwick.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-gilead.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-sanofi.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-oliver-wyman.svg" className="img-fluid " alt="Caterplace" />
            </div>
            <div>
              <img src="../assets/images/client-lutron.svg" className="img-fluid " alt="Caterplace" />
            </div>
          </Slider>
          
        </div>
      </section>
      <Footer />
    </div>
  );
}
