import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import { Button } from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonialSlider = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 6,
  slidesToScroll: 1,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

interface ILandingProps {
  isAuth: boolean;
}

export function Landing({ isAuth }: ILandingProps) {
  const navigate = useNavigate();

  function onContact() {
    navigate("/contact");
  }

  function onLogin() {
    navigate("/login");
  }
  return (
    <>
      <section className="container pb-5 text-center d-flex justify-content-center align-items-center">
      </section>
      <section className="pb-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="h1 pb-3">Reliable & Rewarding Business Catering</h1>
            </div>
          </div>

          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <ul className="list-unstyled list-homepage">
                <li className="pt-3">&bull; Earn CaterCash Rewards on every order you place.</li>
                <li className="pt-3">&bull; Receive high quality value with reliable, vetted Caterers near you.</li>
                <li className="pt-3">&bull; Get full support and advice for every order.</li>
              </ul>
            </div>
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <img
                src="../assets/images/happy-human.png"
                className="img-fluid"
                alt="Contact Information Icon"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light py-5 logo-carousel">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1 className="h2 text-center pb-5">Trusted Catering Service For Corporations</h1>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="row">
            <div className="col text-center d-flex justify-content-center align-items-center">
              <img
                src="../assets/images/2560px-Bausch_Health_logo.svg.png"
                className="img-fluid"
                width="200px"
                alt="amgen"
              />
            </div>
            <div className="col text-center d-flex justify-content-center align-items-center">
              <img
                src="../assets/images/2560px-HSBC_logo_(2018).svg.png"
                className="img-fluid"
                width="200px"
                alt="spectrum"
              />
            </div>
            <div className="col text-center d-flex justify-content-center align-items-center">
              <img
                src="../assets/images/2560px-Stripe_Logo,_revised_2016.svg.png"
                className="img-fluid"
                width="200px"
                alt="fenwick"
              />
            </div>
            <div className="col text-center d-flex justify-content-center align-items-center">
              <img
                src="../assets/images/Abbott_Laboratories_logo.svg.png"
                className="img-fluid"
                width="200px"
                alt="fenwick"
              />
            </div>
            <div className="col text-center d-flex justify-content-center align-items-center">
              <img
                src="../assets/images/Charter_Communications_logo.svg.png"
                className="img-fluid"
                width="200px"
                alt="fenwick"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-12 col-md-8 offset-md-2 text-center">
              <h1 className="h2">Hear What People Are Saying About Us</h1>
            </div>
          </div>

          <div className="row gx-lg-5">
            <div className="col-lg-4 pt-4">
              <div className="card bg-light border-0 testimonial-card">
                <div className="card-body">
                  <h5 className="card-title d-flex">
                    <img
                      src="../assets/images/ally.png"
                      className="img-fluid align-self-start testimonial-author-img"
                    />
                    <div className="w-100 m-2">
                      <p className="testimonial-name">Ally</p>
                      <p className="testimonial-job">
                        Executive
                        <br />
                        Administrator
                      </p>
                    </div>
                  </h5>
                  <p className="card-text text-center pt-4 small">
                    “Ordering lunch for my staff used to be the most stressful part of my day, and
                    now it’s the best. They have a ton of restaurants to choose from that also offer
                    individually wrapped options.“
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pt-4">
              <div className="card bg-light border-0 testimonial-card">
                <div className="card-body">
                  <h5 className="card-title d-flex">
                    <img
                      src="../assets/images/merissa.png"
                      className="img-fluid align-self-start testimonial-author-img"
                    />
                    <div className="w-100 m-2">
                      <p className="testimonial-name">Merrisa</p>
                      <p className="testimonial-job">
                        Account
                        <br />
                        Executive
                      </p>
                    </div>
                  </h5>
                  <p className="card-text text-center pt-4 small">
                    “CaterPlace understands me. I like to eat and I like to go shopping. CaterPlace
                    literally rewards me on EVERY catering order. Then, I use my CaterCash rewards
                    and go shopping on Amazon!“
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pt-4">
              <div className="card bg-light border-0 testimonial-card">
                <div className="card-body">
                  <h5 className="card-title d-flex">
                    <img
                      src="../assets/images/rachel.png"
                      className="img-fluid align-self-start testimonial-author-img"
                    />
                    <div className="w-100 m-2">
                      <p className="testimonial-name">Rachel</p>
                      <p className="testimonial-job">
                        Pharmaceutical
                        <br />
                        Sales Rep
                      </p>
                    </div>
                  </h5>
                  <p className="card-text text-center pt-4 small">
                    “Last year I earned $2900 to spend on Amazon for something I’m already doing-
                    Bringing lunch to the office! Thank you CaterPlace.“
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light pb-5 py-5">
        <div className="container-fluid h-100">
          <div className="row py-5">
            <div className="col-12 col-md-8 offset-md-2 text-center">
              <h1 className="h2">Trusted and Used By The Best Restaurants</h1>
            </div>
          </div>

          <Slider {...testimonialSlider} className="testimonialSlider h-100 d-flex">
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-2.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-3.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-4.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-5.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-6.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-7.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-8.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-9.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-10.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-11.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-12.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-13.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-14.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src="../assets/images/trusted-15.png"
                className="img-fluid"
                style={{ width: "150px" }}
              />
            </div>
          </Slider>
        </div>
      </section>
      <section className="d-none">
        <div className="container py-5">
          <div className="row pb-5">
            <div className="col-12 col-md-8 offset-md-2 text-center">
              <h1 className="h2">Learn How CaterPlace Can Work For You</h1>
            </div>
          </div>

          <div className="row gx-lg-5">
            <div className="col-lg-4 pt-4">
              <div className="card border-0">
                <div className="card-body">
                  <h5 className="card-title d-flex">
                    <img src="../assets/images/home-works-1.png" className="img-fluid" />
                  </h5>
                  <h3 className="card-text text-center pt-4 h4">For Caterers</h3>
                  <p className="card-text text-center">
                    Want a bigger footprint? Now you do. Learn more about Caterers
                  </p>
                  <div className="text-center">
                    <Link to="/caterer">
                      <Button variant="contained" className="signin-btn mt-5">
                        Learn More About Caterers
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pt-4">
              <div className="card border-0">
                <div className="card-body">
                  <h5 className="card-title d-flex">
                    <img src="../assets/images/home-works-2.png" className="img-fluid" />
                  </h5>
                  <h3 className="card-text text-center pt-4 h4">CaterCash</h3>
                  <p className="card-text text-center">
                    Loyalty has its benefits. As it should. Learn more about CaterCash.
                  </p>
                  <div className="text-center">
                    <Link to="/faq#faq-catercash">
                      <Button variant="contained" className="signin-btn mt-5">
                        Learn More About CaterCash
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pt-4">
              <div className="card border-0">
                <div className="card-body">
                  <h5 className="card-title d-flex">
                    <img src="../assets/images/home-works-3.png" className="img-fluid" />
                  </h5>
                  <h3 className="card-text text-center pt-4 h4">For Customers</h3>
                  <p className="card-text text-center">
                    Learn more about your new best friend, CaterPlace.
                  </p>
                  <div className="text-center">
                    <Link to="/about">
                      <Button variant="contained" className="signin-btn mt-5">
                        Learn More About CaterPlace
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-cp convinced">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="why-cp-cnt">
                <h1 className="h1">Not Convinced?</h1>
                <h4 className="h4">Here's a few more reasons to sign up</h4>
                <ul>
                  <li>
                    Our average user earns over <strong>$2,000 a year to spend at Amazon</strong>{" "}
                    through CaterCash rewards!
                  </li>
                  <li>
                    On the road and need help entering an order? No problem, our concierge team can
                    submit the order for you!
                  </li>
                  <li>
                    Don't see your caterer on our platform? Just let us know which caterer you'd
                    like us to add, and we'll give you a CaterCash reward once we add them!
                  </li>
                  <li>
                    Know any friends that also order catering? <strong>Earn CaterCash</strong> for
                    each friend you refer to CaterPlace that submits a qualifying order of $300 or
                    more!
                  </li>
                  <li>
                    Need a custom solution? Just ask and we’ll try our best to work something out!
                  </li>
                </ul>
                <div className="action-btn">
                  {!isAuth && (
                    <Button variant="contained" color="primary" onClick={onLogin}>
                      Sign Up
                    </Button>
                  )}
                  <Button
                    className="call-btn"
                    variant="contained"
                    color="secondary"
                    onClick={onContact}>
                    Schedule a Call
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="why-cp-img">
                <img
                  src="../assets/images/convinced-drawing.svg"
                  className="img-fluid w-100"
                  alt="Caterplace"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
